<template>
  <div class="forget-container" v-title data-title="忘记密码">
    <!-- 头部欢迎信息 -->
    <HeaderWelcome></HeaderWelcome>
    <div class="top-system">
      <div class="info-wrap">
        <span class="name">武汉市前期物业管理电子招投标系统</span>
        <div class="line-1px"></div>
        <span class="forget">忘记密码</span>
      </div>
    </div>
    <div class="content-wrap">
      <div class="info-wrap">
        <div class="title">重置密码</div>
        <div class="form-wrap">
          <el-form :model="dataForm" ref="dataFormRef" class="my-Form" :rules="rules">
            <el-form-item prop="phone">
              <el-input v-model="dataForm.phone" placeholder="输入手机号" autocomplete="off">
                <c-icon slot="prefix" name="icon-shurushoujihao"></c-icon>
              </el-input>
            </el-form-item>
            <el-form-item prop="verifyCode" class="verifyCode-wrap">
              <el-input v-model="dataForm.verifyCode" placeholder="短信验证码" autocomplete="off">
                <c-icon slot="prefix" name="icon-duanxinyanzhengma"></c-icon>
              </el-input>
              <el-button v-if="!showCodeSecond" class="send-btn" type="primary"
                @click="handleSendCode">发送验证码</el-button>
              <span v-else class="second-time">{{ secondTextVal }}</span>
            </el-form-item>
            <el-form-item prop="password">
              <el-popover popper-class="my-popper-custom" placement="right" width="300"
                trigger="manual" :visible-arrow="false" v-model="showPassTip">
                <div class="pass-tip-wrap" :class="['safe' + passwordSafeType]">
                  <div class="arrow"></div>
                  <div class="deg">
                    强度：{{ passwordSafeTxtObj[passwordSafeType] }}
                  </div>
                  <div class="line-6px"></div>
                  <div class="msg">
                    请至少输入 8 个字符。需要包含字母、数字、特殊符号三种类型。
                  </div>
                </div>
                <el-input slot="reference" v-model="dataForm.password" type="password"
                  placeholder="8 - 16位密码，区分大小写" autocomplete="off" :maxlength="16"
                  @input="checkPass" show-password @blur="showPassTip=false" @focus="showPassTip=true">
                  <c-icon slot="prefix" name="icon-mima"></c-icon>
                </el-input>
              </el-popover>
            </el-form-item>
          </el-form>
          <el-button class="submit-btn" type="primary" @click="handleSubmitForm('dataFormRef')">确认
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import HeaderWelcome from "@/components/common/header/vue/header-welcome.vue";
import { RegisterSendCode } from "@/axios/api/main.js";
import { forgetPawwsordReset } from "@/axios/api/system.js";
import { v4 } from "uuid";
import {
  checkMobilePhoneRule,
  checkPasswordComflex,
} from "@/utils/validateRuleUtil.js";
import JSEncrypt from "jsencrypt";
export default {
  name: "Forgetpass",
  components: {
    HeaderWelcome,
  },
  data() {
    return {
      passwordSafeType: null, // 1-不安全，2-有风险，3-安全
      passwordSafeTxtObj: {
        1: "不安全",
        2: "有风险",
        3: "安全",
      },
      dataForm: {
        phone: "", // 手机号
        verifyCode: "", // 验证码
        password: "", // 新登录密码
      },
      showPassTip: false, // 校验密码强度标识
      uuId: "",
      codeTimer: null,
      showCodeSecond: false, // 显示验证码倒计时
      secondNum: 60,
      rules: {
        phone: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("手机号不能为空"));
              } else {
                if (!checkMobilePhoneRule(value)) {
                  callback(new Error("请输入有效手机号"));
                }
                callback();
              }
            },
          },
        ],
        password: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("密码不能为空"));
              } else {
                if (value.length < 8) {
                  callback(new Error("密码不能少于8个字符"));
                }
                if (!checkPasswordComflex(value)) {
                  callback(
                    new Error("密码必须包含字母、数字、特殊符号三种类型")
                  );
                }
                if (value === this.dataForm.confirmPassword) {
                  this.$refs["dataFormRef"].clearValidate(["confirmPassword"]);
                }
                callback();
              }
            },
          },
        ],
        verifyCode: [{ required: true, message: "验证码不能为空" }],
        // account: [{ required: true, message: '账号不能为空' }],
        // password: [{ required: true, message: '密码不能为空' }],
      },
    };
  },
  computed: {
    secondTextVal() {
      return this.secondNum + "s";
    },
  },
  mounted() {},
  beforeCreate() {
    Vue.prototype.$loading = {
      show: () => {
        this.loading = true;
      },
      hide: () => {
        this.loading = false;
        this.loadingText = "";
      },
      text: (loadingText) => {
        this.loadingText = loadingText;
      },
    };
  },
  methods: {
    // 校验密码强度，并且提示
    checkPass() {
      if (this.dataForm.password) {
        if (this.dataForm.password.length <= 10) {
          this.passwordSafeType = 1;
        } else if (this.dataForm.password.length <= 12) {
          this.passwordSafeType = 2;
        } else {
          this.passwordSafeType = 3;
        }

        this.showPassTip = true;
        // setTimeout(() => {
        //   this.showPassTip = false
        // }, 3000)
        return false;
      } else {
        this.showPassTip = false;
        return true;
      }
    },
    // 登录
    handleSubmitForm(formName) {
      console.log(formName);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let encryptor = new JSEncrypt();
          let publicKey =
            "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCqPvlpJjq2wOBQnkZ1oiepyX2lKq/LIjuG+C17Fdd+gsYQTUJbVNLgNcBXtymSAgmllpBc1J3U0Ojf07j58olnb7jMTGRlsBsmFEhNY51pv2KfbHjNfnhvI3Fi6eO1bPmfwj4dIPDJP8c9ChFtWfliwVxF8yFryEtMb3HqLa6w5QIDAQAB";
          encryptor.setPublicKey(publicKey);
          forgetPawwsordReset({
            phone: this.dataForm.phone,
            code: this.dataForm.verifyCode,
            deviceId: this.uuId,
            password: encryptor.encrypt(this.dataForm.password),
          })
            .then((res) => {
              console.log(res);
              if (JSON.stringify(res.success) == "false") {
                return;
              }
              this.$router.push({ path: "/login" });
              this.$message.success("重置密码成功");
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    async handleSendCode() {
      this.$refs.dataFormRef.validateField("phone", async (error) => {
        console.log(error);
        if (!error) {
          this.uuId = v4();
          const param = {
            userName: this.dataForm.userName,
            phone: this.dataForm.phone,
            type: "1", // 1通过手机号2通过邮箱
            deviceId: this.uuId,
            smsType: '2',
          };
          await RegisterSendCode(param);
          this.showCodeSecond = true;
          this.codeTimer = setInterval(() => {
            if (this.secondNum == 0) {
              clearInterval(this.codeTimer);
              this.showCodeSecond = false;
              this.secondNum = 60;
            } else {
              this.secondNum--;
            }
          }, 1000);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.form-wrap {
  width: 510px;
  margin: 0px auto;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ::v-deep .my-Form {
    .el-form-item {
      height: 42px;
      width: 450px;
      .el-form-item__label {
        color: #333333;
        font-size: 16px;
        font-weight: 400;
        padding-right: 20px;
        margin-bottom: 0px;
      }
      &.phone-wrap {
        .el-form-item__content {
          // margin-left: 200px !important;
          position: relative;
          .el-input-group__prepend {
            color: #333;
            background-color: #fff;
            border: 1px solid #cccccc;
            border-right: none;
            box-sizing: border-box;
            font-size: 16px;
            border-radius: 4px 0px 0px 4px;
          }
          input {
            border-radius: 0px 4px 4px 0px !important;
          }
          // .phone-area {
          //   position: absolute;
          //   top: 0px;
          //   left: -50px;
          //   width: 50px;
          //   height: 42px;
          //   line-height: 42px;
          //   text-align: center;
          //   color: #333;
          //   border: 1px solid #cccccc;
          //   border-right: none;
          //   box-sizing: border-box;
          //   font-size: 16px;
          //   border-radius: 4px 0px 0px 4px;
          // }
        }
      }
      &.verifyCode-wrap {
        width: 330px;
        .el-form-item__content {
          position: relative;
          .send-btn {
            position: absolute;
            height: 42px;
            top: 0px;
            right: -120px;
          }
          .second-time {
            position: absolute;
            top: 0px;
            right: -120px;
            width: 110px;
            height: 42px;
            line-height: 42px;
            text-align: center;
            color: #333;
            border: 1px solid #cccccc;
            box-sizing: border-box;
            font-size: 16px;
          }
        }
      }
      .el-form-item__content {
        input {
          height: 42px;
          font-size: 16px;
          padding-left: 20px;
          &::placeholder {
            color: #ccc;
            font-size: 16px;
          }
        }
        .el-input__prefix {
          left: 20px;
          i {
            font-size: 20px;
          }
        }
        .txt {
          margin-left: 10px;
          color: #4184f4;
          font-size: 16px;
          cursor: pointer;
        }
      }
      &.agree {
        .el-form-item__content {
          .el-form-item__error {
            top: calc(100% - 20px);
          }
        }
      }
    }
  }
  .btn-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 28px;
    .submit-btn {
      width: 240px;
      height: 50px;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
    }
    .login-btn {
      width: 240px;
      height: 50px;
      background-color: #ffffff;
      border-color: #666;
      font-size: 16px;
      font-weight: 400;
      color: #666;
      margin-left: 30px;
    }
  }
}
</style>
<style lang="less" scoped>
.my-popper-custom {
  margin-left: 45px !important;
  background-color: #fff;
  color: #666666;
  padding: 14px 20px 20px;
  font-size: 16px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  .pass-tip-wrap {
    display: flex;
    flex-direction: column;
    .arrow {
      position: relative;
      width: 0px;
      height: 0px;
      &::after {
        position: absolute;
        display: inline-block;
        top: 48px;
        left: -18px;
        width: 0;
        height: 0px;
        content: "";
        border-style: solid;
        border-width: 8px;
        border-color: #fff #fff transparent transparent;
        transform: rotate(225deg);
        box-shadow: 2px -2px 0px #ebeef5;
      }
    }
    &.safe1 {
      .deg {
        color: #f56c6c;
      }
      .line-6px {
        background-color: #f56c6c;
      }
    }
    &.safe2 {
      .deg {
        color: #e6a23c;
      }
      .line-6px {
        background-color: #e6a23c;
      }
    }
    &.safe3 {
      .deg {
        color: #52c41a;
      }
      .line-6px {
        background-color: #52c41a;
      }
    }
    .deg {
      color: #f56c6c;
      font-weight: 400;
      margin-bottom: 8px;
    }
    .line-6px {
      width: 100%;
      height: 6px;
      background-color: #f56c6c;
      border-radius: 3px;
      margin-bottom: 16px;
    }
  }
  .popper__arrow {
    left: -12px !important;
    border-width: 8px 12px;
    border-right-color: #fff !important;
    &::after {
      border-right-color: #fff !important;
    }
  }
}
.forget-container {
  background: #fff;
  height: 100%;
  .top-system {
    width: 100%;
    min-width: 1200px;
    background: #fff;
    .info-wrap {
      width: 1200px;
      margin: 0px auto;
      height: 100px;
      display: flex;
      align-items: center;
      .name {
        color: #666666;
        font-size: 30px;
        font-weight: 400;
      }
      .line-1px {
        margin: 0px 30px;
        height: 50px;
        width: 1px;
        background-color: #707070;
      }
      .forget {
        font-size: 18px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
  .content-wrap {
    width: 100%;
    min-width: 1200px;
    height: calc(100% - 140px);
    background: #f8f9fd;
    padding-top: 62px;
    overflow: hidden;
    .info-wrap {
      width: 1200px;
      margin: 0px auto;
      height: 527px;
      overflow: hidden;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      .title {
        height: 69px;
        line-height: 69px;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        border-bottom: 1px solid #cccccc;
        box-sizing: border-box;
      }
      .form-wrap {
        width: 300px;
        margin: 0px auto;
        margin-top: 70px;
        ::v-deep .my-Form {
          .el-form-item {
            height: 42px;
            width: 300px;
            &.verifyCode-wrap {
              width: 180px;
              .el-form-item__content {
                position: relative;
                .send-btn {
                  position: absolute;
                  height: 42px;
                  top: 0px;
                  right: -120px;
                }
                .second-time {
                  position: absolute;
                  height: 42px;
                  top: 0px;
                  right: -120px;
                  width: 110px;
                  height: 42px;
                  line-height: 42px;
                  text-align: center;
                  border: 1px solid #cccccc;
                  box-sizing: border-box;
                  font-size: 16px;
                }
              }
            }
            .el-form-item__content {
              input {
                height: 42px;
                font-size: 16px;
                padding-left: 46px;
                &::placeholder {
                  color: #ccc;
                  font-size: 16px;
                }
              }
              .el-input__prefix {
                left: 20px;
                i {
                  font-size: 20px;
                }
              }
            }
          }
        }
        .submit-btn {
          width: 100%;
          height: 50px;
          background-color: #4184f4;
          border-color: #4184f4;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          margin-top: 38px;
        }
      }
    }
  }
}
</style>
